import React from "react"
import SectionCurrentSponsors from "../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "../utilities/gatsbyLink"
import styled from "styled-components"

const NotFoundPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            relativePath: {
              eq: "background/202002_Ascend_Website_Images_Hero_Who.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Layout>
            <Seo
              title="404: Not found"
              location={`https://ascendevent.com/404/`}
              description="You've found the 404 page, but there must be something you want. Try https://www.webpt.com/"
            />

            <section className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="small-12 cell text-center">
                  <HugeText>404</HugeText>
                </div>
                <div className="small-12 cell text-center">
                  <p className="section-title">Uh-oh! You got a broken link.</p>
                  <p className="section-body" style={{ marginBottom: "2rem" }}>
                    Don’t worry; you’re a musculoskeletal expert. You can
                    restore function. To do so, try these here:
                  </p>
                </div>
                <div className="small-6 cell text-right">
                  <Link to="/" className="button hollow large">
                    Ascend Home
                  </Link>
                </div>
                <div className="small-6 cell medium-text-left">
                  <a
                    href="mailto:events@webpt.com"
                    className="button hollow large"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </section>
            <SectionCurrentSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
          </Layout>
        )
      }}
    />
  )
}

export default NotFoundPage

const HugeText = styled.p`
  font-weight: 900;
  font-size: 240px;
`
